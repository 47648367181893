import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Skills = () => {

  const technicalSkills = ['React', 'HTML', 'CSS', 'Bootstrap', 'Sass', 'Python', 'Flask'];
  const creativeSkills = ['Adobe Photoshop', 'Adobe Illustrator', 'Adobe XD', 'Figma', 'Blender 3D', 'Unreal Engine'];

  return (
    <Container className="about-section py-4 py-md-5">
      <Row className='mb-4'>
        <Col lg={12}>
          <Row>
            <Col lg={12} className='pb-4' >
              <h3 className='text-center'>Technical Skills</h3>
              <div className="skill-chips justify-content-center">
                {technicalSkills.map((skill, index) => (
                  <div key={index} className="skill-chip">
                    {skill}
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={12}>
              <h3 className='text-center'>Creative Skills</h3>
              <div className="skill-chips justify-content-center">
                {creativeSkills.map((skill, index) => (
                  <div key={index} className="skill-chip">
                    {skill}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Skills;
