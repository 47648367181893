import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import BookCallCard from './components/BookCallCard';
import About from './components/About';
import Services from './components/Services';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import ChatForm from './components/ChatForm';
import Footer from './components/Footer';
import { Container } from 'react-bootstrap';

const Home = () => {
  return (
    <div>
      <div className='pattern'>
        <Header />
        <Hero />
      </div>    
      <Services/>
      <Container>
      <About />   
    </Container>
    <Portfolio />
   
        <BookCallCard />
        <ChatForm/>

        <Footer/>
    </div>
  );
};

export default Home;
