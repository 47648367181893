import React from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import Skills from './Skills';

const About = () => {
  const highlightsData = [
    { title: 'Reliable', text: 'You can count on me to deliver high-quality work on time, every time.' },
    { title: 'Always Available', text: 'I maintain clear and open lines of communication with my clients, ensuring I am always available to answer questions and address concerns.' },
    { title: 'Problem Solver', text: 'I love tackling complex problems and finding effective, efficient solutions.' },
    { title: 'Continuous Learner', text: 'I continually upskill and stay current with the latest web development trends and technologies.' },
    { title: 'Detail Oriented', text: 'I pay great attention to detail, ensuring that no aspect of a project is overlooked.' },
  ];

  return (
    <Container className="my-4">
      <Row className="glass-card-book-call d-flex mb-4">
        <Col md={6} className="about-me text-center">
        <h3>Hello World, I'm Divya Deep S.</h3>
        
      <p className="about-text">
          I'm not just a full-stack web developer, I'm a digital craftsman. I have a knack for Front-end coding, user experience. I've crafted websites from scratch and propelled them to the peak of SEO rankings. 
      </p>
      <p>
          When the screen dims, you might find me diving into the latest thought-provoking novel, orchestrating a victory in a strategic board game or a video game.
      </p>
      <p>
          If you're seeking a dedicated professional who brings a mix of technical prowess, creative problem-solving, and an unwavering commitment to excellence, then we should talk. Let's bring your vision to life.
      </p>

          <strong>Email:</strong> deepwebdesigns@gmail.com
          <p/>
          <strong>Phone:</strong> +1-757-940-2480
      </Col>

      <Col md={6}>
          <h3 className="text-center">Why Choose Me</h3>
          <Carousel>
            {highlightsData.map((data, index) => (
              <Carousel.Item key={index}>
                <div className="carousel-content d-flex flex-column align-items-center justify-content-center h-100">
                  <h3>{data.title}</h3>
                  <p>{data.text}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
