import React from 'react';
import Header from './Header';
import About from './About';
import Skills from './Skills';
import Footer from './Footer';

const AboutPage = () => {
    return (
     <div className='pattern'>
        <Header/>
        <div className='my-5'>
        <About/>
        <Skills/>
        </div>
        <Footer/>
     </div>
     
    );

};

export default AboutPage;