import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';

const Portfolio = () => {
  const project = {
    title: "Tiffins2u",
    description: "A full-stack restaurant website made for a restaurant in Ontario, Canada. The project was developed using React, Bootstrap, and Firebase.",
    image: "/images/tiffins.png",
    features: [
      "Users can place orders and track them in real-time.",
      "Fast and efficient Cart System made from Scratch",
      "The restaurant can easily update and manage their menu items.",
      "Preventing spam by verifying user phone numbers with OTP authentication.",
    ]
  };

  return (
    <Container>
      <Card className="portfolio-card my-5 d-flex">
        <Row noGutters className="flex-grow-1">
          <Col md={6}>
            <Card.Img variant="top" src={project.image} alt={project.title} className="h-100" />
          </Col>
          <Col md={6}>
            <Card.Body className="d-flex flex-column">
              <Card.Title>{project.title}</Card.Title>
              <Card.Text>{project.description}</Card.Text>
              {project.features.map((feature, index) => (
                <p key={index}> {feature}</p>
              ))}
              <div className="mt-2">
                <Button className='custom-button-outline' variant='sm' onClick={() => window.location.href='/projects'}>See More Projects</Button>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default Portfolio;
