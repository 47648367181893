import React, { useState } from 'react';
import { Container, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './navbar.css'

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="lg" variant="dark" className="navbar py-4 custom-navbar">
        <Container className="d-flex justify-content-between">
          <NavLink to="/" className="navbar-brand fs-4">
          <img src="/images/logo.png" alt="Logo" style={{ width: "70px", height: "auto" }} />

          </NavLink>
          <div className="d-flex justify-content-center flex-grow-1">
            <Nav className="navbar-menu-container px-4 d-none d-lg-flex">
              <NavLink to="/" exact className="nav-link custom-nav-link" activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/services" className="nav-link custom-nav-link" activeClassName="active">
                Services
              </NavLink>
              <NavLink to="/about" className="nav-link custom-nav-link" activeClassName="active">
                About Me
              </NavLink>
              <NavLink to="/contact" className="nav-link custom-nav-link" activeClassName="active">
                Contact Me
              </NavLink>
            </Nav>
          </div>
          <NavLink to="/contact" className="custom-button-outline btn d-none d-lg-block">
            Hire me
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleShow} className="d-lg-none" />
        </Container>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose} placement='end' id="offcanvasNavbar" className="custom-offcanvas">
        <Offcanvas.Header closeButton className="btn-close-white">
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column justify-content-center">
            <Nav.Item>
              <NavLink to="/" exact className="nav-link custom-nav-link" activeClassName="active" onClick={handleClose}>
                Home
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/services" className="nav-link custom-nav-link" activeClassName="active" onClick={handleClose}>
                Services
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/about" className="nav-link custom-nav-link" activeClassName="active" onClick={handleClose}>
                About Me
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/contact" className="nav-link custom-nav-link" activeClassName="active" onClick={handleClose}>
                Contact Me
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/contact" className="custom-button-outline btn" onClick={handleClose}>
                Hire me
              </NavLink>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
