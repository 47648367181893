import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

const BookCallCard = () => {
  return (
    <Container className="mb-5">
      <Card className="rounded-lg glass-card-book-call">
        <Row noGutters className="h-100 justify-content-center">
          <Col md={12} lg={8} className="d-flex align-items-center justify-content-center text-center">
            <Card.Body className="px-2 py-4">
              <Card.Title className="card-title-book-call">Take your business to a deeper level</Card.Title>
              <Card.Text className="card-text-book-call">
                Let's have a chat! Book a complimentary call and let's explore how we can boost your conversions and accelerate growth.
              </Card.Text>
              <Button variant="primary" href="https://calendly.com/deepdesigns/30min"  target='new' className="cta-button-book-call px-4">Schedule Your Free Call</Button>
            </Card.Body>
          </Col> 
        </Row>
      </Card>
    </Container>
  );
};

export default BookCallCard;
