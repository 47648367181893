import React from 'react';
import Header from './Header';
import Services from './Services';
import Footer from './Footer';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { Globe2, Palette, Funnel, CursorFill, GearFill, BarChartFill, ShopWindow, ChatRightTextFill, PhoneLandscape } from 'react-bootstrap-icons';

const ServicesPage = () => {
    const services = [
        { title: 'Web Design & Development', description: 'Creating responsive websites & e-commerce solutions.', Icon: Globe2 },
        { title: 'Graphic Design & Branding', description: 'Developing logos, graphics and strengthening brand identity.', Icon: Palette },
        { title: 'UX/UI Design', description: 'Designing user-friendly interfaces with an emphasis on user experience.', Icon: CursorFill },
        { title: 'Digital Strategy', description: 'Creating tailored digital marketing strategies for your business.', Icon: Funnel },
        { title: 'Site Migrations', description: 'Seamlessly moving your site to a new platform without losing data.', Icon: GearFill },
        { title: 'SEO Optimization', description: 'Increasing your website visibility on search engines and to help gain more customers.', Icon: BarChartFill },
        { title: 'Social Media Outreach', description: 'Boosting your online presence through strategic social media campaigns.', Icon: ShopWindow },
        { title: 'Email Template Design', description: 'Designing effective email templates for your marketing campaigns.', Icon: ChatRightTextFill },
        { title: 'Mobile App Development', description: 'Building user-friendly mobile applications for iOS and Android.', Icon: PhoneLandscape }
    
      ];
    
      const initialDisplayCount = 9; // Change this value to how many services you want to initially display.
    return (
      <div className='pattern'>
        <Header />
        <Container className='py-5'>
      <h2 className='mb-5 text-center'>Services</h2>
      <Row xs={1} md={2} lg={3} className="g-4">
        {services.slice(0, initialDisplayCount).map((service, index) => (
          <Col key={index}>
            <Card className="text-center glass-card">
              <Card.Body>
                <service.Icon size={64} className='mb-3' />
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

     </Container>  
     <Footer/>
      </div>
          );
  };
  
  export default ServicesPage;
  