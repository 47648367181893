import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col xs={12} md={4} className="footer-text">
            <h4>About Me</h4>
            <p>My name is Deep - I love creating stunning, functional, and user-friendly websites that help businesses grow.</p>
          </Col>
          <Col xs={12} md={4} className="footer-nav">
            <h4>Navigation</h4>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-white">Home</Link></li>
              <li><Link to="/services" className="text-white">Services</Link></li>
              <li><Link to="/about" className="text-white">About Me</Link></li>
              <li><Link to="/contact" className="text-white">Contact Me</Link></li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-contact">
            <h4>Contact Info</h4>
            <p>Email: deepwebdesigns@gmail.com</p>
            <p>Phone: +1-757-940-2480</p>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col className="text-center">
            © 2023 Deep Design. All Rights Reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
