import React from 'react';
import Header from './Header';
import ChatForm from './ChatForm';
import BookCallCard from './BookCallCard';
import Footer from './Footer';


const ContactPage = () => {
    return (
     <div className='pattern'>
        <Header/>
        <div className='my-5'>
        <ChatForm/>
        <BookCallCard/>
        </div>
        <Footer/>
     </div>
    );

};

export default ContactPage;