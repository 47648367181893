import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

const Hero = () => {
  return (
    <Container className="hero-section pattern  py-md-5" >
      <Row className='align-items-center justify-content-center py-lg-hero'>
        <Col lg={12} className="text-lg-left text-center">
          <h1 className="mb-1">Hello! My Name is <span className='special-heading'>Deep</span>.</h1>
          <h1 className="mb-5">I Weave Dreams into <span className='special-heading'>Design</span>.</h1>
           <div className="d-flex justify-content-center flex-wrap">
            <Button href="/contact" className="custom-button responsive-width">
                Let's Get Started
            </Button>
            <Button href="https://calendly.com/deepdesigns/30min" target='new' className="custom-button-outline responsive-width mx-md-2">
                Book a Free Call
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
