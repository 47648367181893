import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import './Chat.css'

const ChatForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <Container className="chat-form mb-5 p-4">
      <h2 className='text-center pb-4'>Let's Talk</h2>
      <div className="message-row">
        <div className="receiver-icon"><span>DD</span></div>
        <div className="chat-message">
          <p>Hi there! I'm excited to hear about your project. First, what's your name?</p>
        </div>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="message-row right">
          <div className="chat-message">
            <p>Hi, I'm <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder='Your name' /></p>
          </div>
          <div className="sender-icon"><span>You</span></div>
        </div>
        <div className="message-row">
          <div className="receiver-icon"><span>DD</span></div>
          <div className="chat-message">
            <p>Great to meet you, {name}! How can I reach you after our chat?</p>
          </div>
        </div>
        <div className="message-row right">
          <div className="chat-message">
            <p>My email is <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder='Your email' /></p>
          </div>
          <div className="sender-icon"><span>You</span></div>
        </div>
        <div className="message-row">
          <div className="receiver-icon"><span>DD</span></div>
          <div className="chat-message">
            <p>Excellent! Now, could you tell me a bit about your project or any questions you have?</p>
          </div>
        </div>
        <div className="input-message">
          <input className='' value={message} onChange={e => setMessage(e.target.value)} placeholder='Your message....' />
          <Button  type="submit">Send</Button>
        </div>
      </Form>
    </Container>
  );
};

export default ChatForm;
